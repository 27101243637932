var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "content-class": "rounded-lg elevation-2",
      "persistent": "",
      "width": "650"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "90vh"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('misc.information')) + " ")]), _vm.entity ? _c('v-card-text', {
    staticClass: "content__height"
  }, _vm._l(Object.entries(_vm.entity), function (entry) {
    return _c('v-row', {
      key: entry[0],
      staticClass: "text-left"
    }, [_vm.propertiesToShow.includes(entry[0]) ? [_c('v-col', {
      staticClass: "text-right",
      attrs: {
        "tag": "strong",
        "cols": "6"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("".concat(_vm.customParentKey || 'contractData', ".").concat(_vm.translationKey, ".form.").concat(entry[0]))) + " ")]), _vm.constants.DATE_PROPERTIES.includes(entry[0]) ? _c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(entry[1])) + " ")]) : entry[1] && entry[1].name ? _c('v-col', [_vm._v(" " + _vm._s(entry[1].name) + " ")]) : entry[1] && entry[1].title ? _c('v-col', [_vm._v(" " + _vm._s(entry[1].title) + " ")]) : entry[1] && entry[0] === 'assignments' ? _c('v-col', [_c('ul', {
      staticClass: "unstyled__list pa-0 ma-0"
    }, _vm._l(entry[1], function (assignment) {
      return _c('li', {
        key: assignment.id,
        staticClass: "pb-3"
      }, [_vm._v(" " + _vm._s(assignment.name) + " ")]);
    }), 0)]) : entry[1] && entry[0] === 'fileHistory' ? _c('v-col', [_c('ol', {
      staticClass: "pa-0 ma-0"
    }, _vm._l(entry[1], function (file) {
      return _c('li', {
        key: file.id,
        staticClass: "pb-3"
      }, [_vm._v(_vm._s(file.name) + " ")]);
    }), 0)]) : entry[1] && entry[0] === 'statusHistory' ? _c('v-col', [_c('ul', {
      staticClass: "unstyled__list pa-0 ma-0"
    }, _vm._l(Object.keys(entry[1]), function (timestamp) {
      return _c('li', {
        key: timestamp,
        staticClass: "pb-3"
      }, [_c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(timestamp)) + " ")]), _c('span', [_vm._v(" " + _vm._s(entry[1][timestamp]) + " ")])]);
    }), 0)]) : typeof entry[1] === 'string' || typeof entry[1] === 'number' ? _c('v-col', [_vm._v(" " + _vm._s(entry[1]) + " ")]) : _vm._e()] : _vm._e()], 2);
  }), 1) : _vm._e(), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "outlined": "",
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.close')) + " ")]), _vm._t("additional-buttons")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }