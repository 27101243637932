
import { defineComponent, PropType, reactive } from '@vue/composition-api'

import { SupplierOutput } from '@/api/types/supplier'
import { ContractOutput } from '@/api/types/contract'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { FileOutput } from '@/api/types/file'

export default defineComponent({
  name: 'CommonInfoDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    entity: {
      type: Object as PropType<SupplierOutput | ContractOutput | FileOutput>,
      default: null,
    },
    propertiesToShow: {
      type: Array as PropType<(keyof SupplierOutput)[] & (keyof ContractOutput)[]>,
      required: true,
    },
    customParentKey: {
      type: String,
      default: null,
    },
    translationKey: {
      type: String,
      required: true,
    },
  },
  setup: () => {
    const DATE_PROPERTIES = ['createdAt', 'updatedAt', 'start', 'end', 'issuedOn', 'demandStart', 'demandEnd']

    return reactive({
      constants: {
        DATE_PROPERTIES,
      },
      functions: {
        dateDashNotationToDot,
      },
    })
  },
})
